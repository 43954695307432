



































import { Prop, Component, Vue } from 'vue-property-decorator'

@Component
export default class QrComponent extends Vue {
  @Prop() private showMess?: boolean
  @Prop() private linkGroup!: string
}
