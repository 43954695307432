












































































import { Component, Vue } from 'vue-property-decorator'
import QrComponent from '@/views/Group/TopPage/MemberList/QR/Qr.vue'
import InvitebyMail from '@/views/Group/TopPage/MemberList/QR/InvitebyMail.vue'
import InvitebyLink from '@/views/Group/TopPage/MemberList/QR/InvitebyLink.vue'
import InviteConfirm from '@/views/Group/TopPage/MemberList/QR/InviteConfirm.vue'
import { EGroupRegisterType } from '@/models'
import GroupService from '@/services/GroupService'

/**
 * f2-102
 * サークル QRコード招待リンク
 */
@Component({
  components: {
    QrComponent,
    InvitebyLink, 
    InvitebyMail,
    InviteConfirm
  }
})
export default class QrPage extends Vue {
  private linkGroup: string = ''
  private step: EStep = EStep.STEP_QR
  private eStep = EStep
  private inviteMails: string[] = []
  private inviteUserIds: number[] = []
  private isInviteByMail: boolean = false

  created() {
    this.hanldeRouterParams()
    this.setInviteLink()
  }

  hanldeRouterParams() {
    if (this.$route.params.isInviteByMail) {
      this.isInviteByMail = this.$route.params.isInviteByMail === 'true'
    }
    if (this.$route.params.step) {
      this.step = Number(this.$route.params.step)
    }
  }

  setInviteLink() {
    GroupService.getGroupById(this.$route.params.groupId, ['group_link'])
      .then(res => {
        if (res.status === 200) {
          if (res.data.group_link) {
            this.linkGroup = `${window.location.origin}/group/${res.data.group_link}/preview`
          } else {
            this.genNewIntiveLink()
          }
        }
      })
      .catch(err => {
        this.$bvModal.show('modal-error-qr-page')
      })
  }

  copyGroupLink() {
    const link = (this.$refs.qrComponent as any).$refs.groupLink.value
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard
        .writeText(link)
        .then(() => this.$bvModal.show('copy-info-modal'))
        .catch(error => console.log(error))
    } else {
      let textArea = document.createElement('textarea')
      textArea.value = link
      // make the textarea out of viewport
      textArea.style.position = 'fixed'
      textArea.style.left = '-999999px'
      textArea.style.top = '-999999px'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      if (document.execCommand('copy')) {
        this.$bvModal.show('copy-info-modal')
      }
      textArea.remove()
    }
  }

  genNewIntiveLink() {
    this.$blockui.show()
    GroupService.getNewInviteLink(this.$route.params.groupId)
      .then(res => {
        if (res.status === 200)
          this.linkGroup = `${window.location.origin}/group/${res.data}/preview`
      })
      .catch(err => {
        this.$bvModal.show('modal-error-qr-page')
      })
      .finally(() => this.$blockui.hide())
  }

  jumpToInviteMail() {
    this.isInviteByMail = true
    this.step = EStep.STEP_MAIL
  }

  jumpToInviteLink() {
    this.isInviteByMail = true
    this.step = EStep.STEP_LINK
  }

  jumpToInviteConfirm(data: any) {
    this.isInviteByMail = data.isInviteByMail
    this.inviteMails = data.isInviteByMail ? data.data : []
    this.inviteUserIds = data.isInviteByMail ? [] : data.data
    this.step = EStep.STEP_CONFIRM
  }

  backtoQrPage() {
    this.step = EStep.STEP_QR
  }

  backFromInviteConfirm() {
    this.step = this.isInviteByMail ? EStep.STEP_MAIL : EStep.STEP_QR
  }

  // jumpToInviteAuth() {
  //   return ({ name: 'group-members-qr-code-invite-auth' })
  // }

  registerNewMember() {
    let routeData = this.$router.resolve({
      name: 'group-members-register',
      query: {
        type: String(EGroupRegisterType.ADMIN_REGISTER)
      }
    })
    window.open(routeData.href, '_blank')
  }
}

export enum EStep {
  STEP_QR,
  STEP_MAIL,
  STEP_LINK,
  STEP_CONFIRM
}
