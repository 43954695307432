














































































































import { Prop, Component, Vue, Watch } from 'vue-property-decorator'

/**
 * f2-304b1
 * グループ メンバー招待他サークルから選択（ポップアップ等）
 */
@Component
export default class ModalMultiSelect extends Vue {
  @Prop({ default: 'multi-select' }) private id!: string
  @Prop() private title!: string
  @Prop() private mess!: string
  @Prop() private listItem!: any[] //requre `id` field
  @Prop() private listSelected?: any[] //requre `id` field
  @Prop() private displayNameKey!: string
  @Prop() private disableLoadMore!: boolean
  @Prop({ default: 12 }) private row!: number
  @Prop() private noFilterSelected!: boolean
  private cloneList: any[] = []
  private disableUp: boolean = false
  private disableDown: boolean = false
  private isShow: boolean = false

  mounted() {
    this.updateList()
  }

  @Watch('listItem')
  @Watch('listSelected')
  @Watch('$route.query.multi_select')
  updateList() {
    setTimeout(() => {
      this.cloneFromListItem()
      this.disableDirectBtn()
      ;(this.$refs.displayNameArea as any)?.addEventListener(
        'scroll',
        (event: any) => {
          this.disableDirectBtn()
        }
      )
    }, 10)
  }

  handleShow() {
    this.isShow = true
    setTimeout(() => (this.isShow = false), 500)
  }

  cloneFromListItem() {
    const listSelectedIds = this.listSelected?.map(item => item.id)
    this.cloneList = this.listItem
      .filter(item => !listSelectedIds?.includes(item.id))
      .map(item => {
        return {
          selected: item.selected,
          item: item
        }
      })
  }

  handleSelectItem(index: number) {
    this.cloneList[index].selected = !this.cloneList[index].selected
  }

  disableDirectBtn() {
    this.disableUp = (this.$refs.displayNameArea as any)
      ? (this.$refs.displayNameArea as any).scrollTop <= 0
      : true
    if (!this.disableLoadMore) {
      this.disableDown = false
      return
    }
    this.disableDown = (this.$refs.displayNameArea as any)
      ? (this.$refs.displayNameArea as any).scrollTop >=
        32 * (this.cloneList.length - this.row)
      : true
  }

  handleGoTop() {
    if (!this.disableUp) {
      ;(this.$refs.displayNameArea as any).scrollTop = 0
    }
  }

  handleGoUp() {
    if (!this.disableUp) {
      ;(this.$refs.displayNameArea as any).scrollTop =
        32 *
        (Math.floor((this.$refs.displayNameArea as any).scrollTop / 32) - 1)
    }
  }

  handleGoDown() {
    this.loadMore()
    if (!this.disableDown) {
      ;(this.$refs.displayNameArea as any).scrollTop =
        32 *
        (Math.floor((this.$refs.displayNameArea as any).scrollTop / 32) + 1)
    }
  }

  handleGoBottom() {
    this.loadMore()
    if (!this.disableDown) {
      ;(this.$refs.displayNameArea as any).scrollTop = (this.$refs
        .displayNameArea as any).scrollHeight
    }
  }

  loadMore() {
    if (!this.disableLoadMore) {
      this.$emit('loadMore')
    }
  }

  hideModal() {
    this.$bvModal.hide(this.id)
    this.cloneFromListItem()
    this.$router.replace({ query: {} }).catch(() => {})
  }

  onSubmit() {
    const selectedItems = this.cloneList
      .filter(item => item.selected)
      .map(item => item.item)
    this.noFilterSelected
      ? ''
      : (this.cloneList = this.cloneList.filter(item => !item.selected))
    this.$emit('submit', selectedItems)
    this.$bvModal.hide(this.id)
    this.$router.replace({ query: {} }).catch(() => {})
  }
}
