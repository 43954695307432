



































































































































































import { Component, Vue } from 'vue-property-decorator'
import ModalMultiSelect from '@/components/Modal/ModalMultiSelect.vue'
import InviteConfirm from '@/views/Group/TopPage/MemberList/QR/InviteConfirm.vue'
import { MemberName } from '@/models'
import GroupService from '@/services/GroupService'
import GroupMemberService from '@/services/GroupMemberService'

/**
 * f2-304b
 * サークル メンバー招待 他サークルから選択
 */
@Component({ components: { InviteConfirm, ModalMultiSelect } })
export default class InvitebyLink extends Vue {
  private searchName: string = ''
  private listMember: MemberName[] = []
  private groupSelected: any = {}
  private groupSearchList: any[] = []
  private listMemberSearch: MemberName[] = []
  private listMemberGroupSearch: MemberName[] = []
  private infoMess: string = ''
  private searchPage: number = 1
  private searchMaxPage: number = 0
  private groupSearchPage: number = 1
  private groupSearchMaxPage: number = 0
  private limit: number = 50
  // this.$store.state.setting.setting.setting_display
  //   .number_item_lists

  created() {
    this.getGroupTagList()
  }

  /**
   * Call API to get logined user group tags
   */
  getGroupTagList() {
    let groupTags: any[] = []
    let group_id = this.$route.params.groupId
    GroupService.getGroupOrder(1, 99999)
      .then(res => {
        if (res.status === 200) {
          groupTags = res.data.data.map((item: any) => {
            return { id: item.id, value: item.id, text: item.name }
          })
          let groupListExcept = groupTags.filter((item) => item.id != group_id);
          this.groupSearchList = groupListExcept;
          let defaultOption = {
            id: -1,
            value: null,
            text: this.$t('common.form.please_select')
          }
          this.groupSearchList.unshift(defaultOption)
          this.groupSelected = groupListExcept[0]
          this.getMemberNameByGroupSearch()
        }
      })
      .catch(err => {
        this.$bvModal.show('modal-error-invite-link')
      })
  }

  /**
   * Call API to get user not in group of selected group tag
   */
  async getMemberNameByGroupSearch(loadMore?: boolean) {
    const currentPage = this.groupSearchPage;
    GroupMemberService.getMemberNameByGroup({
      group_id: this.$route.params.groupId,
      group_search_id: this.groupSelected.id,
      page: this.groupSearchPage,
      limit: this.limit
    })
      .then(res => {
        if (res.status === 200) {
          if (loadMore && currentPage !== 1) {
            this.listMemberGroupSearch = [
              ...this.listMemberGroupSearch,
              ...res.data.data
            ]
          } else {
            this.listMemberGroupSearch = res.data.data
          }
          this.groupSearchPage++
          this.groupSearchMaxPage = res.data.last_page
        }
      })
      .catch(err => {
        this.$bvModal.show('modal-error-invite-link')
      })
  }

  /**
   * Call API to get user not in group by search their name
   */
  async getSearchedUser(loadMore?: boolean) {
    GroupMemberService.getMembersName({
      group_id: this.$route.params.groupId,
      name: this.searchName.trim(),
      page: this.searchPage,
      limit: this.limit
    })
      .then(res => {
        if (res.status === 200) {
          if (loadMore) {
            this.listMemberSearch = [...this.listMemberSearch, ...res.data.data]
          } else {
            this.listMemberSearch = res.data.data
          }
          this.searchPage++
          this.searchMaxPage = res.data.last_page
        }
      })
      .catch(error => {
        this.$bvModal.show('modal-error-invite-link')
      })
  }

  /**
   * Handle select group tag
   */
  async hanldeGroupTagChange(index: number) {
    if (this.groupSearchList[index].id !== this.groupSelected.id) {
      this.groupSelected = this.groupSearchList[index]
      this.groupSearchPage = 1
      await this.getMemberNameByGroupSearch()
    }
    this.$bvModal.show('multi-select-group-search')
    this.$router.replace({ query: { multi_select: '1' } }).catch(() => {}) //for modal multi-select watch
  }

  /**
   * Handle users select from multiple select
   */
  async handleSelectedFromMultiModal(listSelected: MemberName[]) {
    await this.updateListMember(listSelected)
    this.scrollToBottom()
  }

  /**
   * Handle scroll to bottom of list slelected user
   */
  scrollToBottom() {
    ;(this.$refs.memberNameArea as any).scrollTop = (this.$refs
      .memberNameArea as any).scrollHeight
  }

  async searchMembersByName() {
    if (this.searchName.trim().length) {
      this.searchPage = 1
      await this.getSearchedUser()
      this.$bvModal.show('multi-select-search-name')
    }
  }

  /**
   * Hanlde update list user selected
   */
  async updateListMember(listMember?: MemberName[]) {
    if (!listMember) {
      this.infoMess = String(this.$t('common.message.cant_find_user'))
      this.$bvModal.show('modal-info')
      return
    }
    const fullList = [...this.listMember, ...listMember]
    const filterDulicates = fullList.filter(
      (val, index, arr) => arr.findIndex(t => t.id === val.id) === index
    )
    this.listMember = [...filterDulicates]
  }

  handleMailInputChange(event: any, member: MemberName, index: number) {
    if ((event as any).keyCode === 8) {
      this.deleteRow(member, index)
    }
  }

  deleteRow(member: MemberName, index: number) {
    this.listMember = this.listMember.filter(item => item.id !== member.id)
    if (this.listMember.length === index) {
      this.setFocusToLastRow()
    } else {
      this.setFocusIndexRow(index)
    }
  }

  setFocusToLastRow() {
    if (this.listMember.length > 1) {
      ;(this.$refs as any).memberNameInput[this.listMember.length - 1].focus()
    }
  }

  setFocusIndexRow(index: number) {
    ;(this.$refs as any).memberNameInput[index + 1].focus()
  }

  jumpToInviteConfirm() {
    if (this.listMember.length) {
      this.$emit('next', {
        data: this.listMember.map(item => item.id),
        isInviteByMail: false
      })
    }
  }

  jumpToInviteAuth() {
    this.$router.push({ name: 'group-members-qr-code-invite-auth' })
  }
}
